<template>
  <div class="kit-tasks">
    <h1 class="subtitle-1 grey--text">Настройки</h1>
    <SettingsList :settings="settings" :user="user" />
  </div>
</template>

<script>
import SettingsList from '@/components/settings/SettingsList'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    SettingsList
  },
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      user: 'auth/user'
    })
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    ...mapActions('settings', ['getSettings'])
  }
}
</script>
