<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="py-1 px-5">
      <v-row>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Изображение</div>
          <div class="imgContainer">
            <img :src="url() + product.productPicture.path" alt="Изображение" />
          </div>
        </v-col>
        <v-col class=".col">
          <v-row>
            <v-col md="1" class=".col">
              <div class="caption grey--text">Язык</div>
            </v-col>
            <v-col class=".col">
              <div class="caption grey--text">Наименование</div>
            </v-col>
          </v-row>
          <v-row v-for="localization in product.productLocalizations" :key="localization.id">
            <v-col md="1" class=".col">
              {{ localization.lang }}
            </v-col>
            <v-col class=".col">
              {{ localization.title }}
            </v-col>
          </v-row>
        </v-col>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Артикул</div>
          <div>{{ product.article }}</div>
        </v-col>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Кол-во</div>
          <div>{{ product.default_count }}</div>
        </v-col>
        <v-col class=".col">
          <div>
            <v-row>
              <v-col class=".col">
                <div class="caption grey--text">Начало</div>
              </v-col>
              <v-col class=".col">
                <div class="caption grey--text">Конец</div>
              </v-col>
              <v-col class=".col">
                <div class="caption grey--text">Цена</div>
              </v-col>
              <v-col class=".col">
                <div class="caption grey--text">Обновлено</div>
              </v-col>
            </v-row>
            <v-row v-for="price in product.productPrices" :key="price.id">
              <v-col class=".col">
                {{ formatDate(price.price_start) }}
              </v-col>
              <v-col class=".col">
                {{ formatDate(price.price_end) }}
              </v-col>
              <v-col class=".col">
                {{ price.price }}
              </v-col>
              <v-col class=".col">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ price.updatedAt | fromNow }}
                    </div>
                  </template>
                  {{ price.updatedAt | format }}
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col md="8" class=".col">
          <div class="caption grey--text">Название в чеке</div>
          <div>{{ product.receipt_name }}</div>
        </v-col>
        <v-col md="2" class=".col">
          <div class="caption grey--text">Единица измерения</div>
          <div>{{ product.receipt_unit }}</div>
        </v-col>
        <v-col md="2" class=".col">
          <div class="caption grey--text">Налоговая группа</div>
          <div>{{ taxName(product.receipt_tax) }}</div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from 'moment'
import ProductWorker from '@/helpers/product/ProductWorker'
import config from '@/config/config'
export default {
  props: {
    product: Object,
    user: {
      type: Object,
      required: false
    }
  },
  filters: {
    format(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fromNow(date) {
      return moment(date).fromNow()
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YY HH:mm')
    },
    url() {
      return config.pictureBaseUrl
    },
    taxName(tax) {
      return ProductWorker.getTaxGroupName(tax)
    }
  }
}
</script>
<style>
.col {
  margin: 0;
  padding: 1px;
}
img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.imgContainer {
  width: 90%;
  height: 100px;
}
</style>
