<template>
  <v-expansion-panels hover>
    <SettingsListItem v-for="setting in settings" :key="setting.id" :setting="setting" :user="user" />
  </v-expansion-panels>
</template>

<script>
import SettingsListItem from './SettingsListItem'

export default {
  props: {
    settings: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: false
    }
  },
  components: {
    SettingsListItem
  }
}
</script>
